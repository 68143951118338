import React, { useState, useEffect, useRef } from 'react';
import Layoutas from '../../components/Layout';
import Mapbox from '../../mapbox'
import LegendCard from '../../mapbox/LegendCard'
import Calculate from '../../util/calculate';

import Sidetab from '../../components/Sidetab'
import Overview from './map_vec_contents/Overview'
import MapAreaView from './map_vec_contents/MapAreaView'
import AcademyView from './map_vec_contents/AcademyView'
import { Modal, Divider } from 'antd';
import '../index.css';

import Star from '/images/university.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TumbonAddr from '../../util/TumbonAddr'
import region from '../../util/region';
import api from '../../util/api';
import getProvinceName from '../../util/getProvinceName'

const ModalContect = ({ modalUniversity }) => {
  if (!modalUniversity) return null;
  if (!Array.isArray(modalUniversity.major)) modalUniversity.major = JSON.parse(modalUniversity.major)
  let OBJMajor = {}
  modalUniversity.major.forEach(key => {
    if (!OBJMajor[key]) {
      OBJMajor[key] = 1
    } else {
      OBJMajor[key] += 1
    }
  })

  return (<>
    <div className='text-4xl'>{modalUniversity?.hostname}</div>
    <div className='text-xl'>ที่อยู่ ตำบล{modalUniversity?.tumbon_name} อำเภอ{modalUniversity?.city_name} จังหวัด{modalUniversity?.province_name}</div>
    <Divider style={{ margin: 8 }} />
    <div className='text-2xl m-2'>ได้รับทุนจากโครงการนวัตกรรมสายอาชีพชั้นสูง {modalUniversity?.sum_vec} คน</div>
    <Divider style={{ margin: 4 }} />
    <div className='text-xl m-2'> สนับสนุนสาขาอาชีพ {Object.keys(OBJMajor).length} สาขา</div>
    {
      Object.keys(OBJMajor)
        .map(key => ({ key: key, value: OBJMajor[key] }))
        .map(item => (<div className='text-xl m-2'> {`สาขา${item.key}   ${item.value} ทุน`}</div>))
    }
  </>)
}

const FullMap = (props) => {
  const [initData, setInitData] = useState(null)
  const [open, setOpen] = useState(true)
  const [tabvalue, setTabValue] = React.useState(0);
  const [visible, setVisible] = useState(true);
  const [modalSchool, setModalSchool] = useState({
    visible: false,
  });

  const [modalUniversity, setModalUniversity] = useState({
    visible: false,
  });

  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);

  const handleOk = (e) => {
    setVisible(false);
  };

  const [filter, setFilter] = React.useState({
    minView: "country",
    view: 'country',
    year: "2566",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  const updateFilter = (res) => {
    setFilter({ ...res })
  }

  let tab_content = [
    {
      title: 'ภาพรวม',
      content: <Overview
        data={initData}
        filter={filter}
        setFilter={updateFilter} />
    },
    {
      title: 'ดูตามพื้นที่',
      content: <MapAreaView
        data={initData}
        filter={filter}
        ShowModalUniversity={(data) => {
          setModalUniversity({
            visible: true,
            ...data
          })
        }}
        setFilter={updateFilter}
      />
    },
    {
      title: 'ดูตามสถาบันที่เด็กนักเรียนได้เข้ารับการศึกษา',
      content: <AcademyView
        data={initData}
        filter={filter}
        setFilter={updateFilter}
      />
    },
  ]

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { region: region.getRegion(item), province: item, total: 0, vec: 0, university_count: 0, major_count: 0 } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], total: 0, vec: 0, university_count: 0, major_count: 0 } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, vec: 0, university_count: 0, major_count: 0 } });
    data.forEach((item) => {
      let total = (item.sum_vec)
      let count = (item.sum_vec)
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {
          !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
          !province[index]['vec'] ? province[index]['vec'] = count : province[index]['vec'] += count
          !province[index]['university_count'] ? province[index]['university_count'] = 1 : province[index]['university_count'] += 1
          !province[index]['major_count'] ? province[index]['major_count'] = item.major_group.length : province[index]['major_count'] += item.major_group.length
        }
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
          !city[index]['vec'] ? city[index]['vec'] = count : city[index]['vec'] += count
          !city[index]['university_count'] ? city[index]['university_count'] = 1 : city[index]['university_count'] += 1
          !city[index]['major_count'] ? city[index]['major_count'] = item.major_group.length : city[index]['major_count'] += item.major_group.length
        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['vec'] ? tumbon[index]['vec'] = count : tumbon[index]['vec'] += count
          !tumbon[index]['university_count'] ? tumbon[index]['university_count'] = 1 : tumbon[index]['university_count'] += 1
          !tumbon[index]['major_count'] ? tumbon[index]['major_count'] = item.major_group.length : tumbon[index]['major_count'] += item.major_group.length
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['vec'] ? tumbon[index]['vec'] = count : tumbon[index]['vec'] += count
          !tumbon[index]['university_count'] ? tumbon[index]['university_count'] = 1 : tumbon[index]['university_count'] += 1
          !tumbon[index]['major_count'] ? tumbon[index]['major_count'] = item.major_group.length : tumbon[index]['major_count'] += item.major_group.length
        }
        res = tumbon
      }
    })
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === filter.region)
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }


    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['vec'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['vec'] / result.map(item => item.vec).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })


    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.vec)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.vec)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.vec)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]

    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.vec)
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const handleChangeTab = (event, newValue) => {
    setFilter({
      ...filter, ...{
        view: filter.minView,
        region: "ทั้งหมด",
        province_name: filter.minView !== "province" ? "ทั้งหมด" : filter.province_name,
        city_name: null,
        tumbon_name: null,
      }
    })
    setTabValue(newValue);

  };

  const onclickBorder = (layer, filterBorder) => {
    if (tabvalue == 0) {
      setTabValue(1)
    }
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      if (property.type === 'school') {
        setModalSchool({
          visible: true,
          ...property
        })
      } else if (property.type === 'university') {
        setModalUniversity({
          visible: true,
          ...property
        })
      }
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }

  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  useEffect(async () => {
    let [res_vec] = await Promise.all([api.getInitData('vec')])
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        setInitData({
          ...res_vec.deepdata.province[getProvinceName(pid)],
          university2562: res_vec.deepdata.university2562.filter(item => item.province_name === getProvinceName(pid)),
          university2563: res_vec.deepdata.university2563.filter(item => item.province_name === getProvinceName(pid)),
          university2564: res_vec.deepdata.university2564.filter(item => item.province_name === getProvinceName(pid)),
          university2565: res_vec.deepdata.university2565.filter(item => item.province_name === getProvinceName(pid)),
          university2566: res_vec.deepdata.university2566.filter(item => item.province_name === getProvinceName(pid)),
        })
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        setInitData(res_vec.deepdata)
      }
    }
  }, [])

  useEffect(async () => {
    console.log('initDatainitData',initData)
    if (initData) {
      let dataContextUniversity = initData['university' + filter.year]
      if (filter.region !== 'ทั้งหมด') dataContextUniversity = dataContextUniversity.filter(item => item.region === filter.region)
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        region: filter['region'],
        layer: filter.view
      }, convertMapContent(filter.view, dataContextUniversity))
      let data_university = [...new Set(dataContextUniversity)]
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
        } else {
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name))]
        }
      }
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'university', layer: 'marker', data: data_university.map(item => ({ ...item, type: 'university' })), icon: Star },
      ])
      let data_legend = [
        { name: 'จำนวนนักเรียนที่ได้รับทุน', value: response.data.reduce((acc, cur) => acc + cur.vec, 0), unit: 'คน' },
        { name: 'จำนวนสถาบันที่เด็กนักเรียนได้เข้ารับการศึกษา', value: response.data.reduce((acc, cur) => acc + cur.university_count, 0), unit: 'สถาบัน' },
        { name: 'จำนวนสาขาที่สนับสนุน', value: response.data.reduce((acc, cur) => acc + cur.major_count, 0), unit: 'สาขา' },
      ]
      setLegendData({
        ...legend_data,
        title: filter.province_name,
        subtitle: filter.city_name,
        data: data_legend,
      })
    }

  }, [filter, initData])

  return (
    <Layoutas Role={['partner', 'eef', 'partner_province']} isHideFooter>
      <Modal
        width={'800px'}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        visible={modalUniversity.visible}
        okButtonProps={{ style: { display: 'none' } }}
        centered={true}
        CancelText={'ปิด'}
        onOk={handleOk}
        onCancel={() => {
          setModalUniversity({ visible: false })
        }}
      >
        <ModalContect modalUniversity={modalUniversity} />
      </Modal>


      <div className='relative overflow-hidden'>
        <div className='w-[100vw] h-[100vh]'>
          <Mapbox
            id={'map-cct'}
            filter={filter}
            data={data_map}
            lng={99}
            lat={13.4}
            zoom={5.2}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={'จำนวนโรงเรียนในประเทศไทย'}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />
          <div className='absolute top-[90px] left-2 z-1'>
            <LegendCard {...legend_data} />
            <div className='justify-left mt-2 mx-auto'>
              {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> ย้อนกลับ
              </button> : <></>}
            </div>
          </div>
          <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <span className='font-bold center mx-1 mb-2'>สัดส่วนนักเรียนที่ได้รับทุน</span>
              <div className='flex'>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}>
                  </div>
                  <div class="font-bold text-left">
                    มาก
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}>
                  </div>
                  <div class="font-bold text-right">
                    น้อย
                  </div>
                </div>
                <div className='ml-4  flex flex-col'>
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}>
                  </div>
                  <div class="font-bold text-center">
                    ไม่มีข้อมูล
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='absolute bottom-[10px] left-[470px] z-1'>
            <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
              <div class="flex flex-row">
                <div class="flex flex-row text-center ml-2">
                  <img src={Star} style={{ width: '20px', height: '20px' }} />
                  <span className='center mx-1 mb-2'>สถาบันที่เด็กนักเรียนได้เข้ารับการศึกษา</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Sidetab
          title="ทุนนวัตกรรมสายอาชีพชั้นสูง"
          tab={initData ? tab_content : []}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={handleChangeTab}
          titleRef='*ทุนนวัตกรรมสายอาชีพ ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือในปีการศึกษา 2562 - 2566'
          subjectTitleRef='*ประมวลผล ณ เดือนธันวาคม 2566'
        />
      </div>
    </Layoutas >
  )
}

export default FullMap
